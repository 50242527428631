<!-- 结算单创建 -->
<template>
  <Modal v-model="showCreateSettlementModal" width="1000">
    <h3 class="p-t-10">结算单名称</h3>
    <Row>
      <i-col span="24">
        <i-input v-model="name" placeholder="可填写结算单名称"></i-input>
      </i-col>
    </Row>
    <h3 class="p-b-10">结算单备注</h3>
    <Row>
      <i-col span="24">
        <i-input v-model="mark" placeholder="可填写结算单备注"></i-input>
      </i-col>
    </Row>
    <div>
      <h3 class="p-t-10">结算公司</h3>
      <Row :gutter="8">
        <i-col span="6">
          <Select v-model="query.agentCompanyId" @on-change="reloadList">
            <Option
              v-for="(company, index) in companyArray"
              :key="index"
              :value="company.id"
              >{{ company.name }}</Option
            >
          </Select>
        </i-col>
      </Row>
      <Row class="p-t-10">
        <i-col span="24"><h3>可结算计费项</h3></i-col>
      </Row>
      <Row :gutter="8" class="m-b-5">
        <i-col span="18">
          <i-input
            v-model="keyword"
            size="small"
            clearable
            placeholder="关键字：支持客户名称、品牌名称、合同编号、产品名称等"
          ></i-input>
        </i-col>
        <i-col span="6">
          <Button
            type="primary"
            size="small"
            icon="ios-search"
            @click="reloadList"
          >
            <span>搜索</span>
          </Button>
        </i-col>
      </Row>
      <Table
        stripe
        border
        :data="list"
        :columns="tableColumns"
        :height="300"
        @on-selection-change="selectionChange"
      ></Table>
      <Row>
        <i-col span="12">
          <span style="height: 28px; line-height: 28px"
            >已选中{{ chkfeeitemIds.length }}条</span
          >
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page
              size="small"
              :total="total"
              :page-size="query.pageSize"
              show-total
              show-sizer
              :page-size-opts="[15, 50, 100, 200, 500]"
              :current="query.pageNumber"
              @on-change="handlePageChanged"
              @on-page-size-change="handlePageSizeChanged"
            >
            </Page>
          </div>
        </i-col>
      </Row>
    </div>

    <div slot="footer">
      <Button type="text" @click="showCreateSettlementModal = false"
        >取消</Button
      >
      <Button type="primary" @click="confirmCreate">确认</Button>
    </div>
  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import { getfeeitempage, createbill } from '@/api/statement/bill'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  data () {
    return {
      showCreateSettlementModal: false,
      companyArray: [],
      total: 0,
      lastPage: true,
      query: {
        feeitemType: 1,
        agentCompanyId: 0,
        billStatus: -1,
        pageSize: 15,
        pageNumber: 1
      },
      list: [],
      tableColumns: [
        {
          type: 'selection',
          key: 'feeitemId',
          width: 60,
          align: 'center'
        },
        {
          title: '合同编号',
          key: 'contractCode'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [h('p', params.row.brandName || '-')])
          }
        },
        {
          title: '产品名称',
          key: 'goodName',
          render: (h, params) => {
            return h('div', [h('p', params.row.goodName || '-')])
          }
        },
        {
          title: '合同总额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.contractAmount)
              )
            ])
          }
        },

        {
          title: '执行周期',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                GetCurrentSchedule(params.row.startDate, params.row.endDate)
              )
            ])
          }
        },
        {
          title: '执行金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.periodExecutedAmount)
              )
            ])
          }
        }
      ],

      currentPageAllItemIds: [],
      chkfeeitemIds: [],
      mark: '',
      keyword: '',
      name: ''
    }
  },
  methods: {
    showModal (companyArray, defaultId) {
      this.companyArray = companyArray
      this.list = []
      this.chkfeeitemIds = []
      this.query.agentCompanyId = defaultId || companyArray[0].id
      this.initItemArray()
      this.showCreateSettlementModal = true
    },
    reloadList () {
      this.query.pageNumber = 1
      this.initItemArray()
    },
    // 加载所有可结算的交易项
    initItemArray () {
      const that = this
      that.query.keyword = that.keyword
      getfeeitempage(this.query).then((res) => {
        res.list.map((item) => {
          item._checked = that.chkfeeitemIds.includes(item.feeitemId)
          return item
        })
        that.currentPageAllItemIds = res.list.map((item) => {
          return item.feeitemId
        })
        that.list = res.list
        that.total = res.totalRow
        that.lastPage = res.lastPage
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initItemArray()
    },

    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initItemArray()
    },
    // 选中的结算项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map((item) => {
        return item.feeitemId
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.push(item)
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.splice(that.chkfeeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    // 确认创建结算单
    confirmCreate () {
      const that = this
      const data = {
        feeitemListJsonStr: JSON.stringify(that.chkfeeitemIds),
        feeitemType: 1,
        mark: that.mark,
        name: that.name,
        agentCompanyId: that.query.agentCompanyId,
        publisherId: that.publisherId
      }
      createbill(data).then((res) => {
        if (res && !res.errcode) {
          that.showCreateSettlementModal = false
          that.$store.commit('set_settlement_update', new Date())
        }
      })
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  }
}
</script>
